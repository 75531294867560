.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* opacity: 0; */
  /* visibility: hidden; */
  transition: all 0.5s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  padding: 0 19px;
}

.modal__form-container {
  position: fixed;
  background-color: white;
  max-width: 430px;
  /* width: 100%; */
  border-radius: 16px;
  padding: 34px 36px 28px;
}

.modal__type_login {
  height: 333px;
}

.modal__type_register {
  height: 417px;
}

.modal__form {
  margin: 0;
  display: flex;
  flex-direction: column;
}

.modal__form-title {
  margin: 0 0 -9px;
  line-height: 32px;
}

.modal__form_close-button {
  position: absolute;
  top: -46px;
  right: -46px;
  background: transparent url(../../images/modalForm-close.svg) center no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.modal__form_submit-button {
  width: 358px;
  height: 64px;
  padding: 20px 114px;
  margin-top: 43px;
  border-radius: 33px;
  border: none;
  box-shadow: 0px 5px 15px 0px #0e1a3933;
  background: rgba(47, 113, 229, 1);
  color: #fff;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.modal__form_submit-button:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.modal__form_submit-button:disabled {
  background-color: rgba(230, 232, 235, 1);
  color: rgba(182, 188, 191, 1);
  cursor: not-allowed;
}

.modal__extra-actions {
  align-self: center;
}

.modal__form_switch-text {
  color: rgba(0, 0, 0, 0.5);
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.modal__form_switch-button {
  color: rgba(0, 0, 0, 0.5);
  padding: 0;
  border-width: 0cap;
  margin-top: 16px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 17px;
  background-color: white;
  cursor: pointer;
}

.modal__form_switch-button_text {
  color: rgba(47, 113, 229, 1);
}

.modal__form_switch-button_text:hover {
  color: rgba(42, 101, 204, 1);
}

.modal__list {
  list-style-type: none;
  padding: 0;
}

/* .modal__input-container {
  position: relative;
} */

.modal__input {
  padding: 0 0 10px;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 17px;
  width: 358px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0);
}

.modal__input-error {
  display: block;
  position: absolute;
  margin: 3px 0 0;
  color: red;
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 12px;
  width: 358px;
}

.modal__text {
  margin: 31px 0 9px;
  font-family: Inter, Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 14.5px;
  font-weight: 400;
  color: rgba(47, 113, 229, 1);
}

.modal__radio {
  margin: 12px 0 32px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  accent-color: #000;
}

.modal__input-radio ~ label {
  color: rgba(0, 0, 0, 0.5);
}

.modal__input-radio:checked ~ label {
  color: black;
}

.modal__switch {
  margin: 0;
  position: relative;
  top: 32px;
  left: 140px;
  font-family: "Cabinet Grotesk Bold", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}
.modal__switch:hover {
  opacity: 0.6;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .modal__form-container {
    padding: 16px;
    /* height: calc(60vh); */
    width: calc(100vw - 32px);
  }

  .modal__form_close-button {
    width: 24px;
    height: 24px;
    top: -40px;
    right: 16px;
  }

  .modal__form-title {
    line-height: 28px;
    margin: 0 0 18px;
  }

  .modal__input {
    width: calc(100vw - 32px);
  }

  .modal__password {
    margin-bottom: 23px;
  }

  .modal__input[name="name"] {
    margin-bottom: 23px;
  }

  .modal__form_submit-button {
    width: calc(100vw - 32px);
  }
}
