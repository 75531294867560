.mobile-modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.mobile-modal__container {
  max-width: 767px;
  height: 230px;
  background-color: #1a1b22;
  width: 100%;
}

.mobile-modal__header {
  max-width: 767px;
  height: 56px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -1px 0px 0px #ffffff33 inset;
}

.mobile-modal__news-explorer {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.mobile-modal__close-button {
  width: 24px;
  height: 24px;
  background-color: transparent;
  background-image: url("../../images/mobileMenu-close.svg");
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
}

.mobile-modal__redirects {
  max-width: 735px;
  height: 146px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.mobile-modal__home-button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 56px;
  text-align: left;
  cursor: pointer;
}

.mobile-modal__saved-news-button {
  background-color: transparent;
  border: none;
  color: #ffffff;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 56px;
  text-align: left;
  cursor: pointer;
}

.mobile-modal__signin-button {
  max-width: 767px;
  width: 100%;
  height: 56px;
  border: 1px solid #fff;
  border-radius: 25px;
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.mobile-modal__logout-button {
  max-width: 767px;
  width: 100%;
  height: 56px;
  border: 1px solid #fff;
  border-radius: 25px;
  cursor: pointer;
  color: #fff;
  background-color: transparent;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}
