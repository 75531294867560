@import url(./vendor/fonts.css);
@import url(./vendor/normalize.css);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h3 {
  margin: 0;
  padding: 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  font-family: Roboto;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
