.main {
  max-width: 1440px;
}

.main__cover {
  background-image: url(../../images/main-background.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto;
}

.main__section {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 80px 416px; */
}

.main__title {
  margin-top: 80px;
  margin-bottom: 32px;
  width: 590px;
  font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 60px;
  line-height: 64px;
  color: white;
}

.main__description {
  margin: 0;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: white;
}

.main__search-error {
  max-width: 600px;
  align-items: center;
  margin: 0 auto;
  padding: 5px;
  font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
  font-size: 25px;
  font-weight: 400;
  line-height: 46px;
  text-align: left;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .main__cover {
    max-width: 1279px;
    background-size: auto;
  }

  .main__title {
    font-size: 36px;
    line-height: 40px;
    margin-top: 38px;
    margin-bottom: 16px;
    width: 385px;
  }

  .main__description {
    max-width: 452px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .main {
    max-width: 100%;
  }
  .main__cover {
    background-size: auto;
  }

  .main__title {
    max-width: 285px;
    max-height: 80px;
    margin: 0 auto;
    padding: 30px 15px 0 19px;
    font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
    font-size: 36px;
    line-height: 40px;
    font-weight: 400;
  }
  .main__description {
    max-width: 288px;
    max-height: 72px;
    margin: 0 auto;
    padding: 16px 16px 0;
    font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}
