.notfound {
  width: calc(100vw - 17px);
  padding: 86px 0 80px;
  /* height: 374px; */
  background-color: rgba(245, 246, 247, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
}

.notfound__img {
  width: 82.5px;
  height: 82.5px;
}

.notfound__title {
  font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
  font-size: 26px;
  line-height: 30px;
  font-weight: 400;
  margin-top: 31.5px;
  margin-bottom: 16px;
}

.notfound__text {
  margin: 0 auto;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: rgba(182, 188, 191, 1);
}
