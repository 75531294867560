.modal__register-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 43px 36px 51px;
  width: 430px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  position: relative;
}

.modal__register-text {
  width: 367px;
}

.modal__login-button {
  padding: 0;
  margin-top: 14px;
  border-width: 0cap;
  font-size: 18px;
  line-height: 22px;
  background-color: white;
  cursor: pointer;
}

.modal__delete-confirm {
  margin-top: 40px;
  color: rgba(255, 77, 0, 1);
}

.modal__delete-cancel {
  margin-top: 24px;
  color: rgba(0, 0, 0, 1);
}
