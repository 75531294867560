.searchlist {
  padding: 80px 15px;
  background-color: rgba(245, 246, 247, 1);
  display: flex;
  flex-direction: column;
  width: calc(100vw - 47px);
  max-width: 1410px;
}

.searchlist__cards {
  padding: 0;
  margin: 64px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}

.searchlist__title {
  margin: 0;
  margin-left: min(max(calc(100vw - 1280px), 0px), 109px);
  font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
  font-size: 40px;
  font-weight: 400;
  line-height: 46px;
}

.searchlist__button {
  max-width: 288px;
  height: 56px;
  /* margin-top: 48px; */
  padding: 17px 90px;
  background-color: #fff;
  border: none;
  border-radius: 25px;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  align-self: center;
  cursor: pointer;
}

.searchlist__button:hover {
  background-color: rgba(232, 232, 232, 1);
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .searchlist {
    max-width: 1279px;
    width: calc(100vw - 97px);
    padding: 40px;
  }

  .searchlist__cards {
    margin: 0 auto;
    gap: 8px;
  }

  .searchlist__title {
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 32px;
  }

  .searchlist__button {
    margin-top: 32px;
    max-width: 240px;
    padding: 16px 74px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .searchlist {
    max-width: 767px;
    width: calc(100vw - 32px);
    padding: 32px 16px 24px;
  }

  .searchlist__cards {
    margin: 0 auto 16px;
    gap: 10px;
  }

  .searchlist__title {
    margin-bottom: 42px;
    font-size: 30px;
    line-height: 34px;
  }

  .searchlist__button {
    margin-top: 0;
  }
}
