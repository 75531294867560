.footer {
  height: 80px;
  box-sizing: border-box;
  padding: 0 104px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
}

.footer__copyright {
  margin: 0;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(182, 188, 191, 1);
}

.footer__buttons {
  display: flex;
}

.footer__button {
  align-self: center;
  border: none;
  padding: 0;
  cursor: pointer;
}

.footer__button_text {
  background: transparent;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin-right: 40px;
}

.footer__button_GH {
  background: url(../../images/GitHub.svg) center no-repeat;
  height: 24px;
  width: 24px;
  margin-right: 24px;
}

.footer__button_LI {
  background: url(../../images/linkedin.svg) center no-repeat;
  height: 24px;
  width: 24px;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .footer {
    max-width: 1279px;
    height: 64px;
    padding: 0 40px 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .footer__button_text {
    align-self: baseline;
  }

  .footer__button_GH {
    margin: 0;
  }
}
