.app {
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .app {
    max-width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .app {
    max-width: 100%;
  }
}
