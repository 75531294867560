.header {
  max-width: 1440px;
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  padding: 19px 104px 0;
  display: flex;
  justify-content: space-between;
}

.header__logo {
  margin-top: 11px;
  font-size: 20px;
  line-height: 24px;
  font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
  font-weight: 700;
}

.header__logo-color {
  color: white;
}

.header__logo-group {
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  margin-right: 20px;
}

.header__button {
  /* align-self: center; */
  border: none;
  background: transparent;
  padding: 0;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  color: white;
}

.header__button-home {
  margin: 11px 42px 0 auto;
}

.header__underline {
  width: 70px;
  height: 3px;
  background-color: white;
  position: absolute;
  margin-top: 23px;
  margin-left: -11px;
  cursor: auto;
}

.header__button-signin {
  width: 176px;
  height: 48px;
  border: 1px solid #fff;
  border-radius: 25px;
}

.header__button-saved {
  margin: 11px 64px 0 34px;
  color: rgba(182, 188, 191, 1);
}

.header__button-logout {
  width: 112px;
  height: 48px;
  border: 1px solid #fff;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__button-text {
  margin: 0;
}

.header__logout-img {
  padding: 4px;
  width: 16px;
  height: 16px;
  margin-left: 12px;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .header {
    height: 66px;
    padding: 14px 40px 0;
  }

  .header__logo {
    margin-top: 9px;
  }

  .header__button {
    height: 24px;
    font-size: 16px;
  }

  .header__button-home {
    margin: 8px 16px 0 auto;
  }

  .header__button-saved {
    width: 160px;
    margin: 8px 16px 0 0;
    text-align: center;
  }

  .header__underline {
    width: 60px;
    margin-top: 17px;
  }

  .header__button-signin {
    width: 152px;
    height: 40px;
  }
  .header__button-logout {
    height: 40px;
  }
}
