.card {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 576px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.card__delete-button {
  background: transparent url(../../images/trash-grey.svg) center no-repeat;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  margin-top: 24px;
  margin-left: 336px;
}

.card__delete-button:hover {
  background: url(../../images/trash-black.svg) center no-repeat;
}

.card__image {
  width: 400px;
  height: 272px;
  object-fit: cover;
  cursor: pointer;
}

.card__description {
  height: 260px;
  display: flex;
  align-items: flex-start;
  padding: 20px 24px 24px;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: flex-start;
}

.card__name {
  margin: 12px 0 16px;
  max-width: 352px;
  height: 60px;
  font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
  font-size: 26px;
  font-weight: 400;
  line-height: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  white-space: normal;
}

.card__favorite-login {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  width: 182px;
  height: 40px;
  position: absolute;
  margin-top: 24px;
  margin-left: 149px;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.card__favorite-remove {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  width: 159px;
  height: 40px;
  position: absolute;
  margin-top: 24px;
  margin-left: 172px;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.card__tag {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  padding: 0 22px;
  height: 40px;
  position: absolute;
  margin-top: 24px;
  margin-left: 24px;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
}

.card__favorite-button {
  background: transparent url(../../images/flag.svg) center no-repeat;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  position: absolute;
  margin-top: 24px;
  margin-left: 336px;
}

.card__favorite-button_pressed {
  background: url(../../images/flag-marked.svg) center no-repeat;
}

.card__favorite-button:hover {
  background: url(../../images/flag-hover.svg) center no-repeat;
}

.card__date {
  margin: 0;
  color: rgba(182, 188, 191, 1);
  font-family: "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
}

.card__text {
  margin: 0;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  /* font-size: 16px; */
  line-height: 22px;
  /* font-weight: 400; */
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  white-space: normal;
}

.card__source {
  margin: auto 0 0;
  font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
  /* font-size: 16px; */
  line-height: 20px;
  font-weight: 700;
  color: rgba(182, 188, 191, 1);
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .card {
    width: 224px;
    height: 420px;
    margin: 0 auto;
  }

  .card__image {
    width: 224px;
    height: 150px;
  }

  .card__tag {
    margin-top: 8px;
    margin-left: 8px;
  }

  .card__delete-button {
    margin-top: 8px;
    margin-left: 176px;
  }

  .card__favorite-button {
    margin-top: 8px;
    margin-left: 176px;
  }

  .card__description {
    padding: 16px;
  }

  .card__name {
    margin: 12px 0 10px;
    font-size: 22px;
    line-height: 24px;
    height: 72px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .card__text {
    -webkit-line-clamp: 4;
    line-clamp: 4;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .card {
    width: 288px;
    height: 440px;
    margin: 0;
  }

  .card__image {
    width: 288px;
    height: 196px;
  }

  .card__tag {
    margin-top: 16px;
    margin-left: 16px;
  }

  .card__delete-button {
    margin-top: 16px;
    margin-left: 232px;
  }

  .card__favorite-button {
    margin-top: 16px;
    margin-left: 232px;
  }

  .card__description {
    padding: 16px;
  }

  .card__name {
    margin: 10px 0 14px;
  }

  .card__text {
    -webkit-line-clamp: 4;
    line-clamp: 4;
  }
}
