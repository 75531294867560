.searchbar {
  margin-top: 88px;
  margin-bottom: 80px;
  max-width: 1440px;
}

.searchbar__form {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 608px;
  height: 64px;
  margin: 0;
  padding: 0;
  border-radius: 33px;
  border: 1px solid #d1d2d6;
  background-color: #fff;
}

.searchbar__input {
  width: 376px;
  height: 22px;
  margin-left: 24px;
  border: none;
  border-radius: 10px;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.searchbar__input:focus {
  outline: none;
}

.searchbar__button {
  width: 168px;
  height: 66px;
  margin: 0;
  padding: 0;
  background-color: #2f71e5;
  position: absolute;
  border: none;
  border-radius: 33px;
  color: #fff;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  right: -2px;
  top: -1px;
  cursor: pointer;
}

.searchbar__button:hover {
  background-color: #347eff;
}

.searchbar__button:active {
  background-color: #2a65cc;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .searchbar {
    max-width: 1279px;
    margin: 48px 0;
  }
  .searchbar__form {
    max-width: 452px;
    height: 54px;
  }

  .searchbar__button {
    height: 56px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .searchbar {
    padding: 122px 16px 32px;
    max-width: 288px;
    height: 128px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 0 auto;
  }

  .searchbar__form {
    width: 288px;
    height: 56px;
  }

  .searchbar__button {
    width: 288px;
    height: 58px;
    right: 1px;
    top: 72px;
  }
}
