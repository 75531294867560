.about__section {
  display: flex;
  align-items: center;
  padding: 80px 0 80px;
  max-width: 1440px;
}

.about__avatar {
  width: 464px;
  height: 464px;
  margin-left: 104px;
  border-radius: 1000px;
  object-fit: cover;
}

.about__text {
  margin-left: 56px;
}

.about__title {
  margin: 0;
  font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 46px;
}

.about__description {
  margin-top: 24px;
  margin-bottom: 0;
  max-width: 600px;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .about__section {
    max-width: 1279px;
    padding: 40px 0 60px;
  }

  .about__avatar {
    width: 232px;
    height: 232px;
    margin-top: 21px;
    margin-left: 40px;
    border-radius: 1000px;
    object-fit: cover;
  }

  .about__text {
    margin-left: 32px;
    align-self: baseline;
  }

  .about__title {
    margin: 0;
    font-size: 30px;
    line-height: 40px;
  }

  .about__description {
    max-width: calc(100vw - 344px);
    margin-top: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .about__section {
    max-width: 767px;
    padding: 32px 16px 128px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .about__avatar {
    max-width: 272px;
    max-height: 272px;
    margin: 0 auto;
  }

  .about__title {
    max-width: 288px;
    font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
    font-size: 30px;
    line-height: 40px;
    text-align: left;
  }

  .about__text {
    margin: 0;
  }

  .about__description {
    margin-top: 16px;
  }
}
