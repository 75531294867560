.saved-news__header {
  padding: 19px 104px 0;
  border-bottom: 1px solid rgba(209, 210, 214, 1);
}

.saved-news__header-button {
  margin-top: -11px;
  color: black;
}

.saved-news__header-logout {
  border: 1px solid black;
}

.saved-news__underline {
  width: 178px;
  height: 3px;
  background-color: black;
  position: absolute;
  margin-top: 23px;
  margin-left: -32px;
  cursor: auto;
}

.saved-news {
  padding: 0;
  max-width: 1440px;
}

.saved-news__info {
  padding: 40px 104px 56px;
}

.saved-news__text {
  margin: 0;
  font-family: "Roboto", "Inter", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.saved-news__text-bold {
  font-weight: 700;
}

.saved-news__grey {
  color: rgba(26, 27, 34, 0.5);
}

.saved-news__title {
  max-width: 530px;
  margin: 28px 0 30px;
  font-family: "Roboto Slab", "Inter", Arial, Helvetica, sans-serif;
  font-size: 40px;
  line-height: 46px;
  font-weight: 400;
}

.saved-news__list {
  padding: 62px 15px;
  margin: 0 auto;
  background-color: rgba(245, 246, 247, 1);
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .saved-news__header {
    padding: 14px 40px 0;
  }

  .saved-news__header-button {
    margin-top: 9px;
  }

  .saved-news__underline {
    margin: 17px 0 0 0;
    width: 160px;
  }

  .saved-news__info {
    padding: 24px 40px 32px;
  }

  .saved-news__grey {
    font-size: 14px;
  }

  .saved-news__title {
    margin: 16px 0;
    font-size: 30px;
    line-height: 34px;
    max-width: 340px;
  }

  .saved-news__list {
    padding: 32px 40px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .saved-news {
    max-width: 767px;
    padding: 0;
  }

  .saved-news__info {
    margin: 0;
    padding: 8px 16px 32px;
  }

  .saved-news__grey {
    margin: 0;
    color: rgba(26, 27, 34, 0.5);
    font-size: 12px;
    line-height: 24px;
  }

  .saved-news__title {
    margin: 16px 0 50px;
    font-size: 30px;
    line-height: 34px;
  }

  .saved-news__text {
    max-width: calc(100vw - 32px);
  }

  .saved-news__list {
    padding: 40px 16px 13px;
    margin: 0;
    display: flex;
  }
}
